<template>
  <div class="auditorium-list">
    <Action-panel :isReady="isPageReady">
      <Button-base
        :type="2"
        :text="translations.labels.button_add_auditorium"
        :hoverInfo="translations.labels.button_add_auditorium_hover"
        :onClick="newItem"
      />
    </Action-panel>
    <TableList :is-ready="isPageReady" :headers="headers" :items="list" />
  </div>
</template>

<script>
import Vue from "vue";
import Component from "vue-class-component";
import ActionPanel from "@/components/panel/ActionPanel";
import InnerPanel from "@/components/panel/InnerPanel";
import TablePanel from "@/components/panel/TablePanel";
import ButtonBase from "@/components/dom/ButtonBase";
import SvgIcon from "@/components/dom/SvgIcon";
import Round from "@/components/dom/Round";
import TableList from "@/components/table/TableList";
import { toastService } from "@/services/toastService.js";

@Component({
  components: {
    ActionPanel,
    InnerPanel,
    TablePanel,
    ButtonBase,
    SvgIcon,
    Round,
    TableList,
  },
  inject: {
    auditoriumService: "auditoriumService",
    toastService: "toastService",
  },
})
export default class auditorium_list extends Vue {
  isPageReady = false;
  model = [];
  search = "";
  async init() {
    try {
      this.model = await this.auditoriumService.list();
      this.isPageReady = true;
    } catch (error) {
      toastService.error(this.translations.errors.somethingWrong);
    }
  }

  async swapEnabled(id, isEnabled) {
    try {
      const status = isEnabled ? "disable" : "enable";
      this.isPageReady = false;

      this.auditoriumService.setEnabled(id, status);
      this.model = await this.auditoriumService.list();

      this.toastSuccessLabel(isEnabled);
      this.isPageReady = true;
    } catch (error) {
      this.isPageReady = true;
      toastService.error(this.translations.errors.somethingWrong);
    }
  }
  toastSuccessLabel(isEnabled) {
    isEnabled
      ? toastService.success(this.translations.success.auditorium_disable)
      : toastService.success(this.translations.success.auditorium_enable);
  }

  newItem() {
    this.$router.push("/auditorium/new");
  }

  get translations() {
    return this.$store.state.translationsStore.currentLang;
  }
  get list() {
    return this.model;
  }
  get headers() {
    return [
      {
        text: this.translations.labels.auditorium_form_code,
        value: "code",
      },
      {
        text: this.translations.labels.auditorium_form_eventCode,
        value: "eventCode",
      },
      {
        text: this.translations.labels.auditorium_form_availableToGuests,
        value: "availableToGuests",
        type: "boolean",
        itemValue: (item) => {
          return item.availableToGuests;
        },
      },
      {
        text: this.translations.labels.links,
        value: "link",
        type: "link",
        icon: "mdi-link-variant",
        formatValue: (item) => {
          return `/link/${item.id}/Auditorium`;
        },
      },
      {
        text: this.translations.labels.liveSession,
        value: "liveSession",
        type: "link",
        icon: "mdi-presentation-play",
        formatValue: (item) => {
          return `/live-session/list/${item.id}/Auditorium`;
        },
      },
      {
        text: this.translations.labels.infoBox,
        value: "infoBox",
        type: "link",
        icon: "mdi-information",
        formatValue: (item) => {
          return `/info-box/list/${item.id}`;
        },
      },
      {
        text: this.translations.labels.table_detail,
        value: "detail",
        type: "link",
        icon: "mdi-feature-search-outline",
        formatValue: (item) => {
          return `/Auditorium/detail/${item.id}`;
        },
      },
      {
        text: this.translations.labels.table_edit,
        value: "edit",
        type: "link",
        formatValue: (item) => {
          return `/Auditorium/edit/${item.id}`;
        },
        icon: "mdi-pencil",
        buttonType: 2,
      },
      {
        text: this.translations.labels.table_enable,
        value: "enable",
        type: "switch",
        itemValue: (item) => {
          return item.isEnabled;
        },
        onAction: (item) => {
          this.swapEnabled(item.id, item.isEnabled);
        },
      },
    ];
  }

  created() {
    this.$store.commit(
      "SET_SECTION_TITLE",
      this.translations.pageTitles.auditoriums_list
    );
  }

  mounted() {
    this.init();
    this.$store.commit(
      "SET_SECTION_TITLE",
      this.translations.pageTitles.auditoriums_list
    );
  }
}
</script>

<style lang="scss">
.auditorium-list {
  &__btn-icon {
    width: 40px;
    height: 40px;
    display: inline-block;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    input:checked + .slider {
      background-color: #2196f3;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px #2196f3;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }

    /* Rounded sliders */
    .slider.round {
      border-radius: 34px;
    }

    .slider.round:before {
      border-radius: 50%;
    }
  }
}
</style>
